// src/Login.js
import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "./config";
import { useAuth } from "./hooks/useAuth";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setLogin } = useAuth();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const res = await axios.post(`${config.backend}/auth/login`, values, { withCredentials: true });
            if (res.status === 200) {
                setLogin(res.data.email); // Set user data from response
                message.success("Login successful!");
                navigate("/admin");
            }
        } catch (error) {
            message.error("Login failed!");
        }
        setLoading(false);
    };

    return (
        <Form name="login" onFinish={onFinish}>
            <Form.Item label="Username" name="username" rules={[{ required: true, message: "Please input your username!" }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Login;
