import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Space, message, Popconfirm, Row, Col } from "antd";
import axios from "axios";
import config from "./config";

const Admin = () => {
    const [referralContainers, setReferralContainers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false); // Modal visibility
    const [editingContainer, setEditingContainer] = useState(null); // To track which container is being edited
    const [form] = Form.useForm();

    // Fetch all referral containers from the backend
    useEffect(() => {
        fetchReferralContainers();
    }, []);

    const fetchReferralContainers = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${config.backend}/referrals`, { withCredentials: true });
            setReferralContainers(data);
            setLoading(false);
        } catch (error) {
            message.error("Error fetching referral containers");
            setLoading(false);
        }
    };

    // Handle create or update referral container
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            if (editingContainer) {
                await axios.put(`${config.backend}/referrals/${editingContainer._id}`, values, { withCredentials: true });
                message.success("Referral container updated successfully");
            } else {
                await axios.post(`${config.backend}/referrals`, values, { withCredentials: true });
                message.success("Referral container created successfully");
            }
            fetchReferralContainers();
            setVisible(false);
            form.resetFields();
        } catch (error) {
            message.error("Error saving referral container");
        }
    };

    // Open modal for adding/editing referral container
    const openModal = (container = null) => {
        setVisible(true);
        setEditingContainer(container);
        if (container) {
            form.setFieldsValue(container);
        } else {
            form.resetFields();
        }
    };

    // Handle referral container deletion
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${config.backend}/referrals/${id}`, { withCredentials: true });
            message.success("Referral container deleted");
            fetchReferralContainers();
        } catch (error) {
            message.error("Error deleting referral container");
        }
    };

    // Columns for referral containers table
    const columns = [
        {
            title: "Service Name",
            dataIndex: "serviceName",
            key: "serviceName",
        },
        {
            title: "URL",
            dataIndex: "url",
            key: "url",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => openModal(record)}>
                        Edit
                    </Button>
                    <Popconfirm title="Are you sure to delete this referral container?" onConfirm={() => handleDelete(record._id)} okText="Yes" cancelText="No">
                        <Button type="link" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Referral management inside each container
    const referralColumns = [
        {
            title: "Plan Name",
            dataIndex: "planName",
            key: "planName",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record, index) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleEditReferral(record, index)}>
                        Edit
                    </Button>
                    <Popconfirm title="Are you sure to delete this referral?" onConfirm={() => handleDeleteReferral(record.planName)} okText="Yes" cancelText="No">
                        <Button type="link" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // Handle referral deletion inside a container
    const handleDeleteReferral = async (planName) => {
        try {
            await axios.delete(`${config.backend}/referrals/${editingContainer._id}/referrals/${planName}`, { withCredentials: true });
            message.success("Referral deleted");
            fetchReferralContainers();
        } catch (error) {
            message.error("Error deleting referral");
        }
    };

    // Add/Edit a referral inside a referral container
    const handleEditReferral = (referral, index) => {
        Modal.confirm({
            title: "Edit Referral",
            content: (
                <Form form={form} layout="vertical" initialValues={referral}>
                    <Form.Item name="planName" label="Plan Name" rules={[{ required: true, message: "Please input the plan name!" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="code" label="Code" rules={[{ required: true, message: "Please input the referral code!" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            ),
            onOk: async () => {
                const values = await form.validateFields();
                try {
                    await axios.post(`${config.backend}/referrals/${editingContainer._id}/referrals`, values, { withCredentials: true });
                    message.success("Referral updated");
                    fetchReferralContainers();
                } catch (error) {
                    message.error("Error updating referral");
                }
            },
        });
    };

    return (
        <div style={{ padding: "20px" }}>
            <Row justify="space-between">
                <Col>
                    <h2>Admin Referral Management</h2>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => openModal()}>
                        Add Referral Container
                    </Button>
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={referralContainers}
                rowKey="_id"
                loading={loading}
                expandable={{
                    expandedRowRender: (record) => <Table columns={referralColumns} dataSource={Object.values(record.referrals)} rowKey="planName" pagination={false} />,
                }}
            />

            {/* Modal for adding/editing referral container */}
            <Modal title={editingContainer ? "Edit Referral Container" : "Add Referral Container"} open={visible} onCancel={() => setVisible(false)} onOk={handleOk}>
                <Form form={form} layout="vertical">
                    <Form.Item name="serviceName" label="Service Name" rules={[{ required: true, message: "Please input the service name!" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="url" label="URL" rules={[{ required: true, message: "Please input the URL!" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="foregroundColor" label="Foreground Color">
                        <Input />
                    </Form.Item>
                    <Form.Item name="backgroundColor" label="Background Color">
                        <Input />
                    </Form.Item>
                    <Form.Item name="iconUrl" label="Icon URL">
                        <Input />
                    </Form.Item>
                    {/* New fields for planName, code, and description */}
                    <Form.Item name="planName" label="Plan Name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="code" label="Code">
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Admin;
