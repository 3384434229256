// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import Login from "./Login";
import Admin from "./Admin";
import Home from "./Home";

import useFavicon from "./hooks/useFavicon";

import "./fonts/GothamLight.ttf";

const App = () => {
    const { isAuthenticated, loading } = useAuth();

    useFavicon();
    
    if (loading) return <div>Loading...</div>; // Show loading indicator while checking auth

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={isAuthenticated ? <Navigate to="/admin" /> : <Login />} />
                <Route path="/admin" element={isAuthenticated ? <Admin /> : <Navigate to="/login" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
};

export default App;
