// src/hooks/useFavicon.js
import { useEffect } from "react";

function useFavicon() {
    useEffect(() => {
        const lightFavicon = "/favicon-dark.ico";
        const darkFavicon = "/favicon-light.ico";

        const setFavicon = (href) => {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.head.appendChild(link);
            }
            link.href = href;
        };

        const updateFavicon = (e) => {
            if (e.matches) {
                // User prefers dark mode
                setFavicon(darkFavicon);
            } else {
                // User prefers light mode
                setFavicon(lightFavicon);
            }
        };

        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

        // Initial check
        updateFavicon(mediaQuery);

        // Listen for changes in the color scheme
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener("change", updateFavicon);
        } else if (mediaQuery.addListener) {
            mediaQuery.addListener(updateFavicon);
        }

        // Cleanup listener on unmount
        return () => {
            if (mediaQuery.removeEventListener) {
                mediaQuery.removeEventListener("change", updateFavicon);
            } else if (mediaQuery.removeListener) {
                mediaQuery.removeListener(updateFavicon);
            }
        };
    }, []);
}

export default useFavicon;
