import React, { useState, useEffect } from "react";
import { Card, message, Space, Avatar, Typography, Flex, Row, Col } from "antd";
import axios from "axios";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import config from "./config";
import { Button, Input, Select } from "antd";
const { Title, Paragraph } = Typography;
const { Search } = Input;

const Home = () => {
    const [referralContainers, setReferralContainers] = useState([]);
    const [value, setValue] = React.useState("horizontal");

    useEffect(() => {
        (async () => {
            await axios
                .get(`${config.backend}/referrals`)
                .then((res) => {
                    setReferralContainers(Array.isArray(res.data) ? res.data : []);
                })
                .catch((err) => {
                    setReferralContainers([]);
                    message.error("Failed to fetch referral codes");
                });
        })();
    }, []);

    const getContrastColor = (bgColor) => {
        const r = parseInt(bgColor.slice(0, 2), 16);
        const g = parseInt(bgColor.slice(2, 4), 16);
        const b = parseInt(bgColor.slice(4, 6), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255; // Calculate relative luminance
        return luminance > 0.5 ? "#000000" : "#FFFFFF"; // Use a threshold to determine contrast color
    };

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text) // Copy text to clipboard
            .then(() => {
                message.success("Copied to clipboard!", 5); // Display success message for 5 seconds
            })
            .catch(() => {
                message.error("Failed to copy text"); // Display error message if copy fails
            });
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: "black",
                    // height: "4vh",
                    margin: "0",
                    // display: "flex",
                    // alignItems: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <div style={{ width: "100%", maxWidth: "800px" }}>
                        <div
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px",
                            }}
                        >
                            <Title level={1} style={{ color: "#ffffff" }}>
                                ReferMe
                            </Title>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    backgroundColor: "#CCCCCC",
                }}
            >
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <div style={{ width: "100%", maxWidth: "800px" }}>
                        <div
                            style={{
                                marginLeft: "20px",
                                marginRight: "20px",
                                // display: "flex",
                                // alignItems: "center",
                                // justifyContent: "center",
                            }}
                        >
                            <div style={{ padding: "20px 0" }}>
                                <Title level={1} style={{ color: "#1f1f1f" }}>
                                    Save More with ReferMe!
                                </Title>
                                <Paragraph style={{ fontSize: "1.2rem", color: "#595959" }}>
                                    Discover exclusive referral codes and save big! At ReferMe, we connect you with referral discounts from top brands, letting you save on your favorite products and
                                    services. Get started today and enjoy the rewards!
                                </Paragraph>
                                <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "20px 0" }}>
                                    {/* Text Paragraph */}
                                    <Paragraph style={{ fontSize: "1.2rem", color: "#595959", flex: "3", marginBottom: 0 }}>
                                        The service you are looking for is not listed? <br /> Request it here:
                                    </Paragraph>
                                    {/* Input and Button Compact Layout */}
                                    <Space.Compact style={{ flex: "1" }}>
                                        <Input style={{ minWidth: "184px" }} placeholder="Example: Binance" />
                                        <Button style={{ maxWidth: "68px" }} type="primary">
                                            Submit
                                        </Button>
                                    </Space.Compact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: "#ffffff", display: "flex", justifyContent: "center", width: "100%" }}>
                <div style={{ width: "100%", maxWidth: "800px" }}>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ paddingTop: "20px", width: "-webkit-fill-available" }}>
                            {referralContainers && referralContainers.length > 0 ? (
                                referralContainers.map((referralContainer) => (
                                    <Space direction="vertical" size={16} style={{ width: "-webkit-fill-available", paddingBottom: "20px" }} key={referralContainer._id}>
                                        <Card
                                            title={
                                                <Space>
                                                    <Avatar src={referralContainer.iconUrl} />
                                                    <a
                                                        href={referralContainer.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: "#" + referralContainer.foregroundColor,
                                                        }}
                                                    >
                                                        {referralContainer.serviceName} <LinkOutlined />
                                                    </a>
                                                </Space>
                                            }
                                            extra={
                                                <Space direction="vertical" size="middle">
                                                    <Space.Compact
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Input defaultValue={referralContainer.code} allowClear={false} />
                                                        <Button type="primary" onClick={() => copyToClipboard(referralContainer.code)}>
                                                            <CopyOutlined />
                                                        </Button>
                                                    </Space.Compact>
                                                </Space>
                                            }
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#" + referralContainer.backgroundColor,
                                                color: getContrastColor(referralContainer.backgroundColor || "FFFFFF"), // Sets contrast-based text color
                                            }}
                                        >
                                            <h2>{referralContainer.planName}</h2>
                                            <p>{referralContainer.description}</p>
                                        </Card>
                                    </Space>
                                ))
                            ) : (
                                <p>No referrals available. Please try again later.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
