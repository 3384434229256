// src/hooks/useAuth.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import { message } from "antd";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        checkAuth();
    }, []);

    const setLogin = async (email) => {
        setIsAuthenticated(true);
    };

    const logout = async () => {
        // Clear the ME cookie and notify the backend
        document.cookie = `ME=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${config.frontend}`;
        try {
            await axios.post(`${config.backend}/auth/logout`, {}, { withCredentials: true });
            setIsAuthenticated(false);
            message.success("Successfully logged out.");
        } catch (err) {
            console.error("Logout error:", err);
            message.error("Failed to log out.");
        }
    };

    const checkAuth = async () => {
        try {
            await axios
                .get(`${config.backend}/auth/jwt`, { withCredentials: true })
                .then((res) => {
                    console.log("Authenticated")
                    setIsAuthenticated(true);
                })
                .catch((err) => {
                    setIsAuthenticated(false);
                });
        } catch (error) {
            console.error("Auth check failed:", error);
            setIsAuthenticated(false);
        } finally {
            setLoading(false);
        }
    };

    return <AuthContext.Provider value={{ loading, isAuthenticated, setLogin, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
