// If env vars are not specified, prod data is used

const config = {
    backendProtocol: process.env.REACT_APP_BACKEND_PROTOCOL,
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    backendPort: process.env.REACT_APP_BACKEND_PORT,
    environment: process.env.REACT_APP_ENVIRONMENT,
    frontendPort: process.env.REACT_APP_FRONTEND_PORT || 3000,
};

config.backend = `${config.backendProtocol}${config.backendUrl}${config.backendPort ? ":" + config.backendPort : ""}`;
config.frontend = `${config.backendProtocol}${config.backendUrl}${config.backendPort ? ":" + config.frontendPort : ""}`;

export default config;
